import React from 'react'
import "./style.css"
import { Col, Container, Row } from 'react-bootstrap'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
export default function ContactBody() {
    return (
        <>
            <div className="form">
                <Container>
                    <h3 className="bold-font form-title">Contact US</h3>
                    <p className="form-p">Click submit this form. This news will surely reach me.</p>
                </Container>
            </div>
            <Container>
                <form action="POST">
                    <Row>
                        <Col md>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="First name"
                                className="mb-3"
                            >
                                <Form.Control type="text" placeholder="first name" />
                            </FloatingLabel>
                        </Col>
                        <Col md>
                            <FloatingLabel controlId="floatingInput" label="Last name">
                                <Form.Control type="password" placeholder="last name" />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <FloatingLabel controlId="floatingInputGrid" label="Email address">
                                <Form.Control type="email" placeholder="name@example.com" />
                            </FloatingLabel>
                        </Col>
                        <Col md>
                            <FloatingLabel controlId="floatingInput" label="Telegram address">
                                <Form.Control type="text" placeholder="Telegram address" />
                            </FloatingLabel>
                        </Col>
                    </Row>
                </form>
            </Container>
        </>
    )
}

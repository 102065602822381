import React from 'react'
import { Badge, Card, Col, Container, Row } from 'react-bootstrap'
import "./style.css"
import GitHubIcon from '@mui/icons-material/GitHub';
import LanguageIcon from '@mui/icons-material/Language';
import img1 from "../../img/anddez.png"
import img2 from "../../img/design.png"
import img3 from "../../img/team.png"
import img4 from "../../img/truck.png"
import img5 from "../../img/clone.png"
export default function Portfolio() {
    const badge = [
        { variant: "danger", text: "html" },
        { variant: "primary", text: "css" },
        { variant: "warning", text: "js" },
        { variant: "dark", text: "bootstrap" },
        { variant: "info", text: "react" },
        { variant: "danger", text: "sass" },
        { variant: "info", text: "tailwindCSS" },
        { variant: "primary", text: "material-ui" },
        { variant: "danger", text: "jQuery" },
    ]
    const projects = [
        {
            id: 1,
            img: img1,
            title: "AndDez",
            description: "This project is designed for disinfection service.",
            github: "https://github.com/AkhmedovBotir/AndDez",
            live: "https://desinfection.akhmedov-botir.uz",
            badges: [
                badge[0], badge[1], badge[2], badge[3], badge[4], badge[7],
            ]
        },
        {
            id: 2,
            img: img3,
            title: "Team website",
            description: "A website made for the team I met during my internship.",
            github: "https://github.com/AkhmedovBotir/team",
            live: "https://team.akhmedov-botir.uz",
            badges: [
                badge[0], badge[1], badge[2], badge[3], badge[4], badge[5],
            ]
        },
        {
            id: 3,
            img: img2,
            title: "Dezign",
            description: "Design site status from figma",
            github: "https://github.com/AkhmedovBotir/design-web",
            live: "https://dezign.akhmedov-botir.uz",
            badges: [
                badge[0], badge[1], badge[2], badge[3], badge[4], badge[5], badge[7],
            ]
        },
        {
            id: 4,
            img: img4,
            title: "Truck web",
            description: "Logistics company website",
            github: "https://github.com/AkhmedovBotir/truck-web",
            live: "https://truck.akhmedov-botir.uz",
            badges: [
                badge[0], badge[1], badge[2], badge[3], 
            ]
        },
        {
            id: 5,
            img: img5,
            title: "Youtube Clone",
            description: "A website powered by the Youtube API",
            github: "https://github.com/AkhmedovBotir/MyTube",
            live: "https://mytube.akhmedov-botir.uz",
            badges: [
                badge[0], badge[1], badge[2], badge[3], badge[4], badge[6], badge[7],
            ]
        }
    ]

    return (
        <div className='portfolio py-5'>
            <Container>
                <div className="portfolio-body">
                    <h1 className='bold-font'>Portfolio</h1>
                    <p>This is where you will display your projects.</p>
                </div>
                <br />
                <Row xs={2} md={3} className="g-4 cardclass">
                    {
                        projects.map(res => {
                            return (

                                <Col className='cardportfolio'>
                                    <Card className='h-100'>
                                        <Card.Img variant="top" style={{ backgroundImage: `url(${res.img})` }} width={100} height={250} />
                                        <Card.Body>
                                            <Card.Title className='fw-bold'>{res.title}</Card.Title>
                                            <Card.Text>
                                                {res.description}
                                            </Card.Text>
                                            {
                                                res.badges.map(badge => (
                                                    <Badge pill key={badge.variant} bg={badge.variant} className='m-1'>{badge.text}</Badge>
                                                ))
                                            }
                                        </Card.Body>
                                        <Card.Footer>
                                            <div className="cardlink w-75 d-flex justify-content-evenly">
                                                <a href={res.github} target="_blank" rel="noopener noreferrer" className="portfolio-btn px-4 py-1 fw-bold"><GitHubIcon className='fs-5' /> Github</a>
                                                <a href={res.live} target="_blank" rel="noopener noreferrer" className="portfolio-btn1 px-4 py-1 fw-bold"><LanguageIcon className='fs-5' /> Live</a>
                                            </div>
                                        </Card.Footer>
                                    </Card>
                                </Col>

                            )
                        })
                    }
                </Row>
            </Container>
        </div>
    )
}

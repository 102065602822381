import React from 'react'
import { ContactBody, Footer, Navbar } from '../components'
import { Container } from 'react-bootstrap'

export default function Contact() {
  return (
    <>
      <Navbar />
      <ContactBody />
      <Footer />
    </>
  )
}
